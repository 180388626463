{
  "home-about" : "About",
  "home-about-field" : "And little about your scavenger hunt.",
  "home-compete" : "Watch your competition progress and compete with your friends.",
  "home-contact" : "Contact",
  "home-contact-us-long" : "Contact us to set up your scavenger hunt. We'll send you a sample scavenger hunt to help you get started.",
  "home-description" : "Scavengor is a web application that allows users to create and share scavenger hunts.",
  "home-email" : "Your email...",
  "home-event-date" : "Event date...",
  "home-join" : "Join my hunt",
  "home-learn-more" : "Learn More",
  "home-name" : "Your name...",
  "home-ongoing-hunt-button" : "Looking for your ongoing hunt?",
  "home-required-field" : "This field is required",
  "home-stay-up-to-date" : "Stay up to date",
  "home-submit" : "Submit",
  "home-take-photos-about" : "Take photos as you scavenge and create lots of laughs.",
  "home-tell-us" : "Tell us a little about what you're looking for in a scavenger hunt.",
  "home-up-to-date-about" : "Keep up to date with your progress and compete with your friends.",
  "home-valid-email" : "Must be a valid email"
}