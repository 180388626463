import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { TolgeeProvider } from "@tolgee/react";

import * as localeEn from './assets/i18n/en.json';
import * as localeEs from './assets/i18n/es.json';

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <TolgeeProvider
        staticData={{
            en: localeEn,
            es: localeEs,
        }}
        apiUrl={process.env.REACT_APP_TOLGEE_API_URL}
        apiKey={process.env.REACT_APP_TOLGEE_API_KEY}
    >
        <App />
    </TolgeeProvider>
)