{
  "home-about" : "Sobre",
  "home-about-field" : "Y poco sobre tu búsqueda del tesoro.",
  "home-compete" : "Mira el progreso de tu competencia y compite con tus amigos.",
  "home-contact" : "Contáctenos",
  "home-contact-us-long" : "Póngase en contacto con nosotros para configurar su búsqueda del tesoro. Le enviaremos una búsqueda del tesoro de muestra para ayudarlo a comenzar.",
  "home-description" : "Scavengor es una aplicación web que permite a los usuarios crear y compartir búsquedas del tesoro.",
  "home-email" : "Tu correo electrónico...",
  "home-event-date" : "Fecha del evento...",
  "home-join" : "Únete a mi cacería",
  "home-learn-more" : "Aprende más",
  "home-name" : "Su nombre...",
  "home-ongoing-hunt-button" : "Buscando una cacería en curso?",
  "home-required-field" : "Este campo es obligatorio",
  "home-stay-up-to-date" : "Estar al día",
  "home-submit" : "Enviar",
  "home-take-photos-about" : "Toma fotos mientras miras y crea muchas risas.",
  "home-tell-us" : "Cuéntanos un poco sobre lo que estás buscando en una búsqueda del tesoro.",
  "home-up-to-date-about" : "Mantente al día con tu progreso y compite con tus amigos.",
  "home-valid-email" : "Debe ser un correo electrónico válido"
}