import React, { useState } from "react"
import CAMERA from "./assets/images/camera.svg"
import SPLASH from "./assets/images/splash.png"
import LEADERBOARDS from './assets/images/leaderboards.png'
import PHOTO from './assets/images/photo.svg'
import TROPHY from './assets/images/trophy.svg'
import { useForm } from "react-hook-form";
import Lottie from "lottie-react";
import checkAnimation from "./assets/animations/98639-successfull.json";
import { T, useTranslate } from "@tolgee/react";
import "./App.css"

const App = () => {
    const [ submitted, setSubmitted ] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const t = useTranslate();

    const onSubmit = async data => {
        console.log(data)
        await fetch("https://scavenge-api.cloud.zipidy.org/items/inquiries", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        setSubmitted(true);
        reset();
        setTimeout(() => {
            setSubmitted(false);
        }, 3000);
    }

    return (
        <div className="app">
            <nav className="navbar">
                <a href="#" className="navbar-brand"><img style={{ width: "3rem", paddingRight: '0.5rem' }} src={CAMERA} alt="Scavengor Logo" />Scavengor</a>
                <div className="navbar-links">
                    <a href="#about"><T>home-about</T></a>
                    |
                    <a href="#contact"><T>home-contact</T></a>
                    |
                    <a href="https://app.scavengor.com"><T>home-join</T></a>
                </div>
            </nav>
            <section id="home" className="section">
                <div className="side-by-side">
                    <div className="left">
                        <h2 className="section-title"><b>Scavengor</b></h2>
                        <p style={{ textAlign: "center" }}>
                            <T>home-description</T>
                        </p>
                        <a href="#about" className="button"><T>home-learn-more</T></a>
                        <p style={{ textAlign: "center" }}><T>home-ongoing-hunt-button</T></p>
                        <a href="https://app.scavengor.com" className="button"><T>home-join</T></a>
                    </div>
                    <div className="right">
                        <img width="120%" src={SPLASH} alt="App screenshots" />
                    </div>
                </div>
                <div className="side-by-side" style={{ flexWrap: "wrap-reverse"}}>
                    <div className="left">
                        <img width="120%" src={LEADERBOARDS} alt="App screenshots" />
                    </div>
                    <div className="right">
                        <h3 className="section-title"><T>home-stay-up-to-date</T></h3>
                        <p style={{ textAlign: "center" }}>
                            <T>home-compete</T>
                        </p>
                    </div>

                </div>
            </section>
            <section id="about" className="section background" style={{ padding: 80, paddingTop: 100, paddingBottom: 140 }}>
                <div>
                    <div className="side-by-side">
                        <div className="left">
                            <h2 className="section-title white"><T>home-about</T></h2>
                            <p className="white">
                                <T>home-take-photos-about</T>
                            </p>
                        </div>
                        <div className="right">
                            <img style={{ maxWidth: 400 }} width="100%" src={PHOTO} alt="Photo icon" />
                        </div>
                    </div>
                    <p className="white">

                    </p>
                    <div className="side-by-side" style={{ flexWrap: 'wrap-reverse' }}>
                        <div className="right">
                            <img style={{ maxWidth: 400 }} width="100%" src={TROPHY} alt="Trophy icon" />
                        </div>
                        <div className="left">
                            <p className="white">
                                <T>home-up-to-date-about</T>
                            </p>
                        </div>
                        
                    </div>
                    <p className="white">
                        <T>home-contact-us-long</T>
                    </p>
                </div>
            </section>
            <section id="contact" className="section" style={{ padding: 10 }}>
                <div className="side-by-side">
                    <div className="left">
                        <h2 className="section-title"><T>home-contact</T></h2>
                        <p>
                            <T>home-tell-us</T>
                        </p>
                    </div>
                    <div className="right">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <input type="text"  id="name" placeholder={t("home-name")} {...register("name", { required: true })}/>
                                {errors.name && <span className="error"><T>home-required-field</T></span>}
                                <input type="email" id="email" placeholder={t("home-email")} {...register("email", { required: true, pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/ })}/>
                                {errors.email && <span className="error"><T>home-valid-email</T></span>}
                                <textarea id="message" rows="3" placeholder={t("home-about-field")} {...register("message", { required: true })}></textarea>
                                {errors.message && <span className="error"><T>home-required-field</T></span>}
                                <input type='date' id="event_date" placeholder={t("home-event-date")} {...register("event_date", { required: false })}/>
                            </div>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <button type="submit" className="button"><T>home-submit</T></button>
                                {submitted && <Lottie style={{ height: 50 }} animationData={checkAnimation} loop={false} reversed={true} />}
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <footer className="footer">
                <div>&copy; Scavengor 2022</div>
                <div>
                    <ul className="footer-list">
                        <li>
                            <a href="#">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#">Terms of Service</a>
                        </li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

export default App